import type { GlobalMessage, GlobalMessageType } from '~/lib/globalMessage'
import clsx from 'clsx'
import { useEffect } from 'react'

const variantStyles: Record<GlobalMessageType, string> = {
  success: 'border-green-600 bg-green-200',
  error: 'border-red-800 bg-red-200',
}

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  message: GlobalMessage
  onDismiss: (id: string) => void
}

function Toast({ message, onDismiss, ...rest }: Props) {
  useEffect(() => {
    const timeout = setTimeout(() => {
      onDismiss(message.id)
    }, 3000)
    return () => clearTimeout(timeout)
  }, [message, onDismiss])

  return (
    <div className="py-2" {...rest}>
      <div
        className={clsx(
          variantStyles[message.type],
          'border px-6 py-4 rounded-lg shadow-lg pointer-events-auto md:w-80',
        )}>
        {message.message}
      </div>
    </div>
  )
}

export default Toast
