import { captureRemixErrorBoundaryError } from '@sentry/remix';
import { cssBundleHref } from '@remix-run/css-bundle';
import type { LinksFunction, LoaderArgs } from '@remix-run/node';
import { json } from '@remix-run/node';
import {
  Link,
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useRouteError } from
'@remix-run/react';
import UilBars from '@iconscout/react-unicons/dist/icons/uil-bars';
import CheeseburgerMenu from 'cheeseburger-menu';

import { createGlobalMessage, type GlobalMessage } from '~/lib/globalMessage';
import { getGlobalMessage, getSession, getUser, sessionStorage } from '~/session.server';
import tailwindStyles from '~/tailwind.css';
import globalStyles from '~/global.css';
import useToggle from './hooks/useToggle';
import { getLeaguesForUser } from './models/leagueMembership.server';
import UserMenu from './components/UserMenu';
import ToastManager from './components/Toast/ToastManager';
import ScrollLock from './components/ScrollLock';
import { useCallback, useEffect, useRef, useState } from 'react';
import { GlobalMessageDispatchContext } from './contexts/GlobalMessage';

export const links: LinksFunction = () => [
{ rel: 'stylesheet', href: tailwindStyles },
{ rel: 'stylesheet', href: globalStyles },
{ rel: 'stylesheet', href: 'https://fonts.googleapis.com/css?family=Audiowide' },
...(cssBundleHref ? [{ rel: 'stylesheet', href: cssBundleHref }] : [])];


export const loader = async ({ request }: LoaderArgs) => {
  const user = await getUser(request);
  let leagueList: Awaited<ReturnType<typeof getLeaguesForUser>> | undefined = undefined;
  if (user) {
    leagueList = await getLeaguesForUser({ userId: user.id });
  }
  const session = await getSession(request);
  const globalMessage = await getGlobalMessage(session);

  return json({
    user,
    leagueList,
    globalMessage,
    ENV: {
      SENTRY_DSN: process.env.SENTRY_DSN,
      GITHUB_CLIENT_ID: process.env.GITHUB_CLIENT_ID
    }
  }, {
    headers: {
      'Set-Cookie': await sessionStorage.commitSession(session)
    }
  });
};

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  return <div>Something went wrong</div>;
};

export default function App() {
  const { value: isMenuOpen, on: openMenu, off: closeMenu } = useToggle();

  const { user, leagueList, globalMessage: globalMessageFromLoader, ENV } = useLoaderData<typeof loader>();
  const [globalMessages, setGlobalMessages] = useState<Array<GlobalMessage>>([]);
  const addGlobalMessage = useCallback((message: GlobalMessage) => {
    setGlobalMessages((messages) =>
    messages.some((m) => m.id === message.id) ?
    messages :
    [...messages, message]
    );
  }, []);
  const globalMessageDispatch = useCallback((type: GlobalMessage['type'], message: string) => {
    addGlobalMessage(createGlobalMessage(type, message));
  }, [addGlobalMessage]);
  const clearGlobalMessage = useCallback((id: string) => setGlobalMessages((messages) => messages.filter((m) => m.id !== id)), []);

  useEffect(() => {
    if (globalMessageFromLoader) {
      addGlobalMessage(globalMessageFromLoader);
    }
  }, [addGlobalMessage, globalMessageFromLoader]);

  const menuContainer = useRef<HTMLDivElement>(null);

  return (
    <GlobalMessageDispatchContext.Provider value={globalMessageDispatch}>
      <html lang="en" className="h-full">
        <head>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width,initial-scale=1" />
          <Meta />
          <Links />
        </head>
        <body className="h-full">
          <div className="min-h-screen flex flex-col pt-2">
            <div className="coolGradient stickyHeader slant shadow-lg px-5">
              <div className="w-full max-w-3xl mx-auto flex flex-row items-center min-h-[3.5rem]">
                <button className="inline-block mr-2 notFullSizeLayout" onClick={openMenu} aria-label="Open menu">
                  <UilBars className="glowyAmber" size="3rem" />
                </button>
                <Link to="/">
                  <h1 className="glowyAmber text-3xl sm:text-5xl">
                    PONG.ROCKS
                  </h1>
                </Link>
              </div>
            </div>
            <div className="flex-1 slant z-10">
              <Outlet />
            </div>
            <div className="coolGradient slant shadow-lg py-2 mb-3 px-5">
              <div className="w-full max-w-3xl mx-auto flex flex-row items-center justify-end text-right">
                <div>
                  © Eddie McLean 2023<br />
                  By using this site you agree not to do anything illegal with it
                </div>
              </div>
            </div>
          </div>
          <div ref={menuContainer}>
            <CheeseburgerMenu
              isOpen={isMenuOpen}
              closeCallback={closeMenu}
              skewY={-5}
              bottomOffset={-26}
              backgroundColor="#f8fafcf4">

              <div className="pt-8">
                {user && <UserMenu user={user} leagueList={leagueList} menuId="mobile-user-menu" onSelect={closeMenu} />}

                {!user && <div className="p-4 flex flex-col gap-4">
                  <Link
                    to="/join"
                    className="flex rounded px-4 py-2 justify-center bg-slate-600 text-blue-100 hover:bg-blue-500 active:bg-blue-600">

                    Sign up
                  </Link>
                  <Link
                    to="/login"
                    className="flex rounded px-4 py-2 justify-center bg-slate-600 text-blue-100 hover:bg-blue-500 active:bg-blue-600">

                    Log In
                  </Link>
                </div>}
              </div>
            </CheeseburgerMenu>
          </div>
          <ScrollLock isActive={isMenuOpen} targetElement={menuContainer} />
          <ScrollRestoration />
          <script
            dangerouslySetInnerHTML={{
              __html: `window.ENV = ${JSON.stringify(ENV)}`
            }} />

          <Scripts />
          <LiveReload />
          <ToastManager messages={globalMessages} onDismiss={clearGlobalMessage} />
        </body>
      </html>
    </GlobalMessageDispatchContext.Provider>);

}