import type React from 'react'
import { useEffect } from 'react'
// @ts-ignore
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

interface Props {
  isActive?: boolean;
  targetElement: React.MutableRefObject<HTMLElement | null>;
}

function ScrollLock({ isActive, targetElement }: Props) {
  useEffect(() => {
    if (isActive && targetElement.current) {
      const element = targetElement.current
      disableBodyScroll(element, { reserveScrollBarGap: true })
      return () => enableBodyScroll(element)
    }
  }, [isActive, targetElement])

  return null
}

export default ScrollLock
