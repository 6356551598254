import { Flipper, Flipped, spring } from 'react-flip-toolkit'
import type { GlobalMessage } from '~/lib/globalMessage'
import Toast from '~/components/Toast/Toast'
import invariant from 'tiny-invariant'

function getOutsideTranslateY(element: HTMLElement) {
  let isSideToastLayout = window.matchMedia('(min-width: 768px)').matches
  const rect = element.getBoundingClientRect()
  if (isSideToastLayout) {
    return -rect.bottom - 16
  } else {
    return window.innerHeight - rect.top
  }
}

function onAppear(element: HTMLElement) {
  const initialTranslateY = getOutsideTranslateY(element)
  element.style.opacity = '1'
  element.style.transform = `translateY(${initialTranslateY}px)`

  spring({
    values: {
      translateY: [initialTranslateY, 0],
    },
    onUpdate: (arg) => {
      invariant(typeof arg === 'object')
      element.style.transform = `translateY(${arg.translateY}px)`
    },
    onComplete: () => {
      element.style.transform = ''
      element.style.opacity = ''
    },
  })
}

function onExit(element: HTMLElement, index: number, onComplete: () => void) {
  element.style.zIndex = '-1'
  const animation = element.animate(
    [
      { opacity: 1 },
      { opacity: 0, easing: 'ease-out' },
    ],
    300,
  )
  animation.addEventListener('finish', () => { onComplete() }, { once: true })
  animation.addEventListener('cancel', () => { onComplete() }, { once: true })
}

interface Props {
  messages: Array<GlobalMessage>
  onDismiss: (id: string) => void
}

function ToastManager({ messages, onDismiss }: Props) {
  const flipKey = messages.map(message => message.id).join(',')

  return (
    <Flipper flipKey={flipKey}>
      <div className="fixed top-0 bottom-0 left-0 right-0 overflow-hidden pointer-events-none px-4 py-2 flex flex-col justify-end items-stretch md:justify-start md:items-end z-20">
        {messages.map(message => (
          <Flipped flipId={message.id} key={message.id} onAppear={onAppear} onExit={onExit}>
            <Toast message={message} onDismiss={onDismiss} />
          </Flipped>
        ))}
      </div>
    </Flipper>
  )
}

export default ToastManager
